/*
 * Persistir datos de la aplicación el almacenamiento local
 */

// Librerías
import store from 'store'

// Key names
const SESSION_DATA = 'SessionData'
const TOKEN = 'COYOTE_AUTH_TOKEN'
const ROLE = 'role'
const COUNTRY = 'country'
const INFO = 'user_info'

// Entradas que se tendrán en cuenta en el almacenamiento
const KEYS = [TOKEN, ROLE, COUNTRY, INFO]

class AppStorage {
  // Inicializa el almacenamiento: si no hay datos hace una limpieza general
  init () {
    if (!KEYS.every(this.getValue)) KEYS.forEach(this.createKey)
  }

  // Crea una llave en el almacenamiento y le asigna un string vacío como valor
  createKey (key) {
    store.set(key, '')
  }

  // Limpia el almacenamiento local
  clearAll () {
    store.clearAll()
  }

  // Borra una entrada del almacenamiento
  removeKey (key) {
    store.remove(key)
  }

  // Actualiza datos de almacenamiento en keyName
  insertIntoKey (key, value) {
    store.set(key, value)
  }

  // Guarda token en almacenamiento
  setAuthToken (token) {
    this.insertIntoKey(TOKEN, token)
  }

  // Guarda rol en almacenamiento
  setRole (role) {
    this.insertIntoKey(ROLE, role)
  }

  // Guarda país en almacenamiento
  setCountry (country) {
    this.insertIntoKey(COUNTRY, country)
  }

  // Guarda la info de usuario en el almacenamiento
  setUserInfo (info) {
    this.insertIntoKey(INFO, info)
  }

  // Actualiza los campos de info de usuario. Recibe un objeto
  updateUserInfo (info) {
    const currentInfo = this.getUserInfo()
    this.setUserInfo(Object.assign({}, currentInfo, info))
  }

  // Regresa todos los datos de una key del almacenamiento
  getValue (keyName) {
    return store.get(keyName)
  }

  // Regresa valor de un atributo dentro de una key en almacenamiento
  getProperty (keyName, valueName) {
    return store.get(keyName)[valueName]
  }

  // Regresa todos los datos de la sesión
  getAllSessionData () {
    return this.getValue(SESSION_DATA)
  }

  // Regresa el token de la sesión
  getAuthToken () {
    return this.getValue(TOKEN)
  }

  // Regresa el rol de la sesión, fallback a colaborador
  getRole () {
    return this.getValue(ROLE) || 'collaborator'
  }

  // Regresa el país del usuario, fallback a 'mexico'
  getCountry () {
    return this.getValue(COUNTRY) || 'mexico'
  }

  // Regresa la info del usuario
  getUserInfo () {
    return this.getValue(INFO)
  }

  // Revisar si hay sesión, regresa boolean
  hasSession () {
    this.init()
    return !!this.getAuthToken()
  }
}

export default new AppStorage()
