// Librerías
import React, { Component } from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

// Se importa el CSS principal para que se parsee el archivo en webpack
import './css/src/main.scss'
// Se importa el service worker custom
import { unregister } from './registerServiceWorker'

// Local storage de la aplicación
import AppStorage from 'api/appStorage'

// Componentes
import Async from 'shared/async-wrapper'

// Carga asíncrona de componentes
const Login = Async(() => import(/* webpackChunkName: "login" */ './app/login'))
const App = Async(() => import(/* webpackChunkName: "app" */ './app'))

class Router extends Component {
  state = {
    isSignedIn: AppStorage.hasSession()
  }
  componentDidMount () {
    window.addEventListener('signin', this.handleSignInChange)
  }
  componentWillUnmount () {
    window.removeEventListener('signin', this.handleSignInChange)
  }
  handleSignInChange = () => {
    this.setState({
      isSignedIn: AppStorage.hasSession()
    })
  }
  render () {
    const { isSignedIn } = this.state
    const component = isSignedIn ? App : Login
    return (
      <GoogleOAuthProvider clientId={`${process.env.GOOGLE_CLIENT_ID}`}>
        <BrowserRouter>
          <Switch>
            <Route path='/ingresar' component={Login} title='Iniciar Sesión' />
            <Route path='/' component={component} />
            <Redirect to='/' />
          </Switch>
        </BrowserRouter>
      </GoogleOAuthProvider>
    )
  }
}

render(<Router />, document.getElementById('app'))
unregister()
