import Loadable from 'react-loadable'

import Loading from 'shared/loading'

// Componente wrapper para hacer lazy-loading de los chunks
// generados con el code-splitting de Webpack
// acepta como parámetro una llamada de import() tipo:
// `() => import(/* webpackChunkName: "nomina" */ './nomina')`
const Async = loader => {
  return Loadable({
    loader,
    loading: Loading
  })
}

export default Async
