// Liberías
import React from 'react'

const Loading = () => (
  <div className='has-text-primary has-text-centered espacio-medium'>
    <br />
    <i className='mdi mdi-loading mdi-spin mdi-36px' />
  </div>
)

export default Loading
